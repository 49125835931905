<template>
  <vs-row class="full-height" :style="{ margin: '0px' }">
    <vs-col
      vs-lg="4"
      vs-xs="12"
      vs-sm="12"
      type="flex"
      vs-justify="center"
      vs-align="center"
      class="login-register-box"
    >
      <vs-row class="spread-logo">
        <vs-col vs-sm="12" vs-xs="12" vs-lg="12" class="text-center">
          <img src="@/assets/images/logo/logo.png" alt="Spread" />
        </vs-col>
      </vs-row>
      <vs-row>
        <vs-col vs-sm="0" vs-xs="0" vs-lg="2"></vs-col>
        <vs-col vs-sm="12" vs-xs="12" vs-lg="8">
          <div class="login-form mb-0">
            <div slot="header">
              <h3 class="mb-1 mainTitle fw-medium">Welcome</h3>
              <p class="mb-0">Please Sign In to your account</p>
            </div>

            <div class="error" color="danger" :active="inputValid">
              <ul v-if="errors.length" class="common-list">
                <li v-for="(error, index) in errors" :key="index">
                  {{ error }}
                </li>
              </ul>
            </div>

            <form @submit="checkForm" novalidate="true" class="login-input">
              <vs-input
                class="w-100 vs-input-large fw-light"
                :class="{ errorInput: errors.length }"
                placeholder="Username"
                v-model="email"
                type="email"
              />
              <div style="position: relative">
                <vs-input
                  class="w-100 vs-input-large fw-light"
                  :class="{ errorInput: errors.length }"
                  :type="passStatus ? 'text' : 'password'"
                  placeholder="Password"
                  v-model="pwd"
                />
                <div class="button-show" @click="handleShowPassword">
                  {{ passStatus ? "hide" : "show" }}
                </div>
              </div>
              <input
                class="btn-block btn btn-primary submit-button"
                type="submit"
                @click.stop.prevent="checkForm"
                value="Sign In"
              />
            </form>
          </div>
        </vs-col>
        <vs-col vs-sm="0" vs-xs="0" vs-lg="2"></vs-col>
      </vs-row>

      <vs-row v-if="messages" class="messageRow">
        <vs-col vs-sm="0" vs-xs="0" vs-lg="2"></vs-col>
        <vs-col
          vs-sm="12"
          vs-xs="12"
          vs-lg="8"
          v-for="(oneMessage, index) in messages"
          :key="index"
        >
          <vs-alert
            v-if="oneMessage"
            :title="oneMessage.title"
            :active.sync="oneMessage.display"
            :color="oneMessage.type.toLowerCase()"
          >
            <div class="close" @click="handleCloseAlert">close</div>
            <div v-html="oneMessage.text"></div>
          </vs-alert>
        </vs-col>
      </vs-row>
    </vs-col>
    <vs-col
      vs-lg="8"
      vs-xs="0"
      vs-sm="0"
      type="flex"
      class="full-height login-register-bg text-center"
      vs-justify="center"
      vs-align="center"
    >
      <vs-row>
        <vs-col vs-w="12">
          <h2 class="fw-light" style="letter-spacing: 6px">
            CASSIEL, MAKE WORK EASY.
          </h2>
        </vs-col>
      </vs-row>
      <vs-row
        class="login-footer-messsage"
        vs-justify="center"
        vs-align="center"
      >
        <vs-col vs-w="12">
          <p></p>
        </vs-col>
      </vs-row>
    </vs-col>
  </vs-row>
</template>

<script>
export default {
  name: "Login",
  data: () => ({
    passStatus: false,
    rememberme: false,
    email: "",
    pwd: "",
    errors: [],
    messages: null,
  }),
  methods: {
    handleCloseAlert: function () {
      this.messages = null;
    },
    handleShowPassword: function () {
      this.passStatus = !this.passStatus;
    },
    checkForm: function (e) {
      this.errors = [];
      if (!this.email) {
        this.errors.push("Username is required.");
      } else if (!this.validEmail(this.email)) {
        this.errors.push("Valid email required.");
      } else {
        this.submit();
      }
      if (!this.pwd) this.errors.push("Password is required.");
      if (!this.errors.length) return true;
      e.preventDefault();
    },

    /* eslint-disable */
    validEmail: function (email) {
      // let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/;
      // return re.test(email);
      return true;
    },

    /* eslint-enable */
    submit() {
      if (this.validEmail(this.email)) {
        this.doLogin();
        return false;
      }
    },

    doLogin() {
      const _self = this;
      if (!this.$store.state.loaderSpinner) {
        this.$vs.loading.close("#global-loading-container>.con-vs-loading");
        this.$vs.loading({
          container: "#global-loading-container",
          type: "corners",
        });
        this.$store.dispatch("setLoaderSPinner");
      }

      const loginUrl = this.$apiList["login"];
      this.$http
        .post(loginUrl, { email: this.email, pass: this.pwd })
        .then((response) => {
          let responseData = response.data;
          this.$store.dispatch("auth/setTokens", responseData);
          this.$store.dispatch("auth/setUser", responseData);
          this.$router.push({ name: "Dashboard" });
        })
        .catch((error) => {
          handleErrorResponse(error);
        });

      function handleErrorResponse(error) {
        _self.$vs.loading.close("#global-loading-container>.con-vs-loading");
        _self.$store.dispatch("clearLoaderSPinner");
        if (
          error.response &&
          error.response.status === 400 &&
          error.response.data &&
          error.response.data.msg
        ) {
          if (Array.isArray(error.response.data.msg)) {
            _self.errors.push(error.response.data.msg.join("\n"));
          } else {
            _self.errors.push(error.response.data.msg);
          }
        } else if (error.response && error.response.status === 403) {
          _self.errors.push("Invalid username and / or password");
        } else {
          _self.errors.push(error);
        }
      }
    },
  },

  mounted() {
    let globalErrors = this.$store.getters["globalMessages/list"];

    if (
      globalErrors &&
      Array.isArray(globalErrors) &&
      globalErrors.length > 0
    ) {
      this.messages = globalErrors;
      this.$store.dispatch("globalMessages/clear");
    }

    const user = this.$store.getters["auth/getUser"];
    const auth = this.$store.getters["auth/getToken"];

    if (auth && user) {
      if (!this.$store.state.loaderSpinner) {
        this.$vs.loading.close("#global-loading-container>.con-vs-loading");
        this.$vs.loading({
          container: "#global-loading-container",
          type: "corners",
        });
        this.$store.dispatch("setLoaderSPinner");
      }
      this.$router.push({ name: "dashboard" });
    } else {
      this.$vs.loading.close();
    }
  },

  computed: {
    inputValid() {
      return !(
        this.validEmail(this.email) &&
        this.pwd &&
        this.errors.length === 0
      );
    },
  },
};
</script>
<style lang="scss">
body {
  background-color: white;
}
.error {
  color: #e41c42;
  font-size: 14px;
  min-height: 19px;
  margin-top: 5px;
  margin-bottom: 6px;
}
.login-input input {
  max-width: 100% !important;
}
.mainTitle {
  font-size: 25px;
}
.login-register-bg {
  margin: 0 auto;
  background: url(../../assets/images/background/auth-bg.jpg) no-repeat center
    center;
  background-position: top -20%;
  background-repeat: no-repeat;
  background-size: cover;
  color: white !important;
  position: relative;
}

.login-register-bg {
  display: flex;
  flex-direction: column;
}

.login-register-box {
  background-color: white !important;
  display: flex !important;
  flex-direction: column;
  position: relative;
}

.login-form {
  form {
    input {
    }

    i.vs-icon {
      top: 50%;
      transform: translateY(-50%);
    }
  }
}

.button-show {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
  cursor: pointer;
  color: #586bb2;
  font-size: 16px;
  height: 100%;
  padding-right: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.spread-logo {
  align-items: center;
  justify-content: center;
  margin-bottom: 80px;
  // transform: translateY(-20%);
}

.login-footer-messsage {
  align-self: flex-end;
  align-items: center;
  justify-content: center;

  position: absolute;
  bottom: 0px;
  left: 50%;
  transform: translateX(-50%);
}
.messageRow {
  bottom: 35px;
  position: absolute;
}
</style>
